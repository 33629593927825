import React, { Component } from 'react'
import { Container, Col } from 'reactstrap'
import {graphql} from "gatsby";
import {fluidImage, fluidImageTablet, fluidImageMobile} from 'components/Image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
// import FormBlock from 'components/FormBlock'

class Contact extends Component {

    render() {

        const props = this.props;

        return (
            <Layout>
                <SEO title="Contact" description="Michael Rose" />
                <Hero 
                headerImage={props.data.headerContactImage.childImageSharp.fluid}
                headerImageTablet={props.data.headerContactImageTablet.childImageSharp.fluid}
                headerImageMobile={props.data.headerContactImageMobile.childImageSharp.fluid}
                headerText="Contact"
                display="none"
                displayLogo="none"/>
                <Container fluid  className="bg-black">
                    <Container className="content">
                        <Col sm={12} xl={8}>
                        <h2 className="pt-5">Office Details</h2>
                        <p>Michael Rose Organisation Ltd<br/>
                        The Old Dairy<br/>
                        Throop Road<br/>
                        Bournemouth <br/>
                        BH8 0DL<br/><br/>

                        Telephone: +44(0) 1202 522711 <br/>
                        Mobile: +44(0) 7720 406980/1<br/>
                        Email: <a href="mailto:michael@michaelroseorg.com">michael@michaelroseorg.com</a><br/><br/>
                        </p>
                        </Col>
                    </Container>
                </Container>
            </Layout>
        )
    }
}

export default Contact


export const ContactQuery = graphql`
query {
    headerContactImage: file(relativePath: { eq: "header/header-contact-1920.jpg" }) {
    	...fluidImage
	}
	headerContactImageTablet: file(relativePath: { eq: "header/header-contact-768.jpg" }) {
        ...fluidImageTablet
    }
    headerContactImageMobile: file(relativePath: { eq: "header/header-contact-576.jpg" }) {
        ...fluidImageMobile
    }
}`